<script lang="ts">
    import { onMount } from 'svelte';
    import { run, showPreferences } from 'vanilla-cookieconsent';
    import config from '../../js/cookieconsent-config';
    import 'vanilla-cookieconsent/dist/cookieconsent.css';

    onMount(() => {
        run(config);
    });

    function openPreferences() {
        showPreferences();
    }
</script>
